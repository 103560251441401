<template>
 <div class="columnnewbox">
  <ul class="columnnewlist">
    <li v-for="item in bookData">
      <span class="imgboxleft">
       <img :src="item.Avatar" alt />
      </span>
      <div class="textboxcenter">
        <router-link tag="h2" class="titleh2" :to="{name:'specialColumnDetail',params:{artid:item.Artid}}">
           {{item.Special}}
          </router-link>
        <div class="text">
          <span class="left">{{item.Creator}}</span>
          <span class="left">{{item.Mtime}}</span>
          </div>
        <p class="textp">{{item.Content}}</p>
      </div>
      <span class="imgboxright" v-if="item.Pic">
        <img :src="item.Pic" alt />
      </span>
    </li>
  </ul>
  <el-pagination
  v-if="total>pageCount"
      background
      @current-change="handleCurrentChange"
      layout="prev, pager, next"
      :page-size.sync="pageCount"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
 </div>
</template>
<script>
import { specialRequest } from "@/api/project.js";
export default {
  data() {
    return {
      bookData:[],
      total:'',
      pageCount:'',
      currentPage:1
    };
  },
  created(){
    this.getSpecial()
  },
  methods: {
     async getSpecial(val) {
      try {
        val=val || '1'
        const result = await specialRequest({
          page: val
        });
        if (result.status == 200) {
          this.bookData = result.data.list;
          this.total=result.data.totalCount
          this.pageCount=result.data.pageCount
        }
      } catch (err) {}
    },
    handleCurrentChange(val) {
      this.getSpecial(val);
    }
  }
};
</script>
<style  lang="less" scoped>
.columnnewbox{
    padding: 15px;
}
.columnnewlist{
  &>li{
    display: flex;
    background: #fff;
    -webkit-box-shadow: 0px 0px 10px #e0e0e0;
    box-shadow: 0px 0px 10px #e0e0e0;
        padding: 15px;
        margin-bottom: 15px;
    .imgboxleft{
      width:32px;
      height: 32px;
      border-radius: 40px;
      border:1px solid #ccc;
      overflow: hidden;
      margin-right: 15px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .textboxcenter{
      flex: 1;
      .titleh2{
        font-size: 14px;
        cursor: pointer;
      }
      .text{
        overflow: hidden;
        font-size: 13px;
        color: #888;
        line-height: 30px;
        .left{
          float: left;
          margin-right: 30px;
        }
      }
      .textp{
        font-size: 13px;
        line-height: 26px;
        color: #333;
        overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;//溢出隐藏
      }
    }
    .imgboxright{
      width: 155px;
      height: 100px;
      margin:25px 0 0 15px;
      border-radius: 2px;
          overflow: hidden;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.el-pagination{
text-align: right;
margin:20px;
}
</style>